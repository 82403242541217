const keys = {
  publicDi: "public_di",
};

const getToken = () => {
  const token = localStorage.getItem("token");
  // if( process.env.NODE_ENV == "development"){
     // token ='dad5f54df3b7b7babf21f4155e57fee5';
  // }
  return token;
};
const setToken = (token) => {
  localStorage.setItem("token", token);
};
const removeToken = () =>{
  localStorage.removeItem("token");
}
const getRandomString = (len) => {
  len = len || 32;
  var chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
  /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
  var maxPos = chars.length;
  var pwd = "";
  for (var i = 0; i < len; i++) {
    pwd += chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return pwd;
};
const setDi = () => {
  localStorage.setItem(keys.publicDi, getRandomString());
};
const getDi = () => {
  if (localStorage.getItem(keys.publicDi) == null) {
    setDi();
  }
  return localStorage.getItem(keys.publicDi);
};

export default {
  setToken,
  getToken,
  getDi,
  removeToken,
};
