<template>
  <div class="main">
    <router-view></router-view>
    <Tabbar class="tabbar" />
  </div>
</template>

<script>
import Tabbar from "./components/Tabbar.vue";

export default {
  name: "App",
  components: {
    Tabbar,
  },
};
</script>

<style lang="scss">
@import url("./style/reset.scss");
@import url("./style/nut.scss");
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  .nut-pagination {
    display: flex;
    font-size: 14px;
    color: #fa2c19;
    width: 100%;
    margin-left: 4rem;
  }
}
.main {
  display: block;
  position: relative;
  height: 100vh;
  background: #f7f8fa;
}
.tabbar {
  margin: auto;
  padding:0.5rem 0;
  position:fixed;
  z-index:999;
  bottom:0rem;
}
.pageBottom {
  margin-bottom: 3.5rem;
}
</style>
