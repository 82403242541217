<template>
  <nut-tabbar @tab-switch="tabSwitch" v-if="isShow">
    <nut-tabbar-item tab-title="订面粉" icon="cart" to="/" />
    <nut-tabbar-item tab-title="查积分" icon="find" to="/points" />
    <nut-tabbar-item tab-title="查订单" icon="order" to="/order" />
    <nut-tabbar-item tab-title="存面粉" icon="shop" to="/save" />
  </nut-tabbar>
</template>

<script>
// import { useRoute } from "vue-router";

export default {
  setup() {
    const noShowUri = ["/privacy", "/using"];
    console.log(window.location.pathname, noShowUri.indexOf(window.location.pathname));
    const isShow = noShowUri.indexOf(window.location.pathname) == -1 ? true : false;
    function tabSwitch(item, index) {
      console.log(item, index);
    }
    return {
      tabSwitch,
      isShow
    };
  },
};
</script>

<style></style>
