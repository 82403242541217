const Booking = () => import("../pages/booking/index.vue");
const Order = () => import("../pages/order/index.vue");
const Create = () => import("../pages/order/create");
const Points = () => import("../pages/points/index.vue");
const Save = () => import("../pages/save/index.vue");
const Login = () => import("../pages/login/index.vue");
const AddressList = () => import("../pages/address/list.vue");
const AddressEdit = () => import("../pages/address/edit.vue");
const AddressAdd = () => import("../pages/address/add.vue");
const Logout = () => import("../pages/logout/index.vue");
const Privacy = () => import("../pages/agreement/privacy");
const Using = () => import("../pages/agreement/using");

const routes = [
  {
    path: "/",
    component: Booking,
    meta: {
      title: "订面粉",
    },
  },
  {
    path: "/booking",
    component: Booking,
    meta: {
      title: "订面粉",
    },
  },
  {
    path: "/order",
    component: Order,
    meta: {
      title: "查订单",
    },
  },
  {
    path: "/points",
    component: Points,
    meta: {
      title: "查积分",
    },
  },
  {
    path: "/save",
    component: Save,
    meta: {
      title: "存面粉",
    },
  },
  {
    path: "/login",
    component: Login,
    meta: {
      title: "登录",
    },
  },
  {
    path: "/address/list",
    component: AddressList,
    meta: {
      title: "收货地址管理",
    },
  },
  {
    path: "/address/add",
    component: AddressAdd,
    meta: {
      title: "添加收货地址",
    },
  },
  {
    path: "/address/edit",
    component: AddressEdit,
    meta: {
      title: "添加收货地址",
    },
  },

  {
    path: "/order/create",
    component: Create,
    meta: {
      title: "下单",
    },
  },
  {
    path: "/logout",
    component: Logout,
    meta: {
      title: "用户详情页",
    },
  },
  {
    path: "/privacy",
    component: Privacy,
    meta: {
      title: "隐私协议",
    },
  },
  {
    path: "/using",
    component: Using,
    meta: {
      title: "使用条款",
    },
  },
];

export default routes;
