import { createApp } from "vue";
import App from "./App.vue";
// 注意：这种方式将会导入所有组件
import NutUI from "@nutui/nutui";
import "@nutui/nutui/dist/style.css";
import router from "./router";
import utils from "./utils";


// 设置 rem 函数
function setRem() {
  // 320 默认大小16px; 320px = 20rem ;每个元素px基础上/16
  let htmlWidth = document.documentElement.clientWidth || document.body.clientWidth;
  console.log(htmlWidth)
  //得到html的Dom元素
  let htmlDom = document.getElementsByTagName('html')[0];
  if (htmlWidth >= 750) {
    //设置根元素字体大小
    htmlDom.style.fontSize = 22 + 'px';
  } else {
    //设置根元素字体大小
    htmlDom.style.fontSize = htmlWidth / 25 + 'px';
  }

}
// 初始化
setRem();
// 改变窗口大小时重新设置 rem
window.onresize = function() {
  setRem()
}
const nanoid = require("nanoid");

//项目启动检测本地cookie是否有di,没有的话，生成并且存入
function checkLocalDi() {
  if (!utils.getDi()) {
    let di = nanoid.nanoid(32);
    utils.setDi(di);
  }
}

/* 路由发生变化修改页面title */
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});
checkLocalDi();
createApp(App).use(NutUI).use(router).mount("#app");
